import * as React from 'react';
import { FooterComponent } from '../../../Components/FooterComponent';
import pricingContent from '../../../../content/pricingPageContent.json';
import PricingComponent from './Components/PricingComponent';

const index = () => {
    return (
        <>
            <section className='TripPricing'>
                <div className='TripPricing__content'>
                    <div className='TripPricing__content-hero'>
                        <div className='container hero-text' >
                            <h5>{pricingContent.heroSection[0]}</h5>
                            <p>{pricingContent.heroSection[1]}</p>
                            {/* <div className='requestLink mt-5'>
                                <CustomLinkBtn text={pricingContent.buttonText[0]} url={REQUEST_PAGE} icon={<BiChevronRight />} />

                            </div> */}
                        </div>
                    </div>
                    <div className='TripPricing__content-prices'>
                        <div className='container'>
                            <div className='pricing_component'>
                            <PricingComponent />
                            </div>
                        </div>
                    </div>
                    <div className='TripPricing__content-services'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-9 col-lg-9 mx-auto'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4 mb-5 mb-md-0'>
                                            <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606047/webbermill-website-assets/static/service/help_cxdzk6.png" alt='service-logo' width={70} />
                                            <h5>{pricingContent.service[0]}</h5>
                                            <p>{pricingContent.serviceText[0]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4 mb-5 mb-md-0'>
                                            <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606048/webbermill-website-assets/static/service/security_ognbuo.png" alt='service-logo' width={70} />
                                            <h5>{pricingContent.service[1]}</h5>
                                            <p>{pricingContent.serviceText[1]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <img src="https://res.cloudinary.com/webbermill/image/upload/v1679606047/webbermill-website-assets/static/service/star_mnpq2f.png" alt='service-logo' width={70} />
                                            <h5>{pricingContent.service[2]}</h5>
                                            <p>{pricingContent.serviceText[2]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='TripPricing__content-faq'>
                        <div className='container'>
                            <div className='faqHeader'>
                                <h5>{pricingContent.faqHeader[0]}</h5>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-9 col-lg-9 mx-auto'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-6 faqs'>
                                            <h5>{pricingContent.faqTitles[0]}</h5>
                                            <p>{pricingContent.faqContent[0]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 faqs'>
                                            <h5>{pricingContent.faqTitles[3]}</h5>
                                            <p>{pricingContent.faqContent[3]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 faqs'>
                                            <h5>{pricingContent.faqTitles[1]}</h5>
                                            <p>{pricingContent.faqContent[1]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 faqs'>
                                            <h5>{pricingContent.faqTitles[4]}</h5>
                                            <p>{pricingContent.faqContent[4]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 faqs'>
                                            <h5>{pricingContent.faqTitles[2]}</h5>
                                            <p>{pricingContent.faqContent[2]}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 faqs'>
                                            <h5>{pricingContent.faqTitles[5]}</h5>
                                            <p>{pricingContent.faqContent[5]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </>
    );
};

export default index;


