import { Link } from 'gatsby';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { CustomButton } from '../../../../Components/CustomButton';
import CustomModal from '../../../../Components/CustomModal';
import { CONTACT_PAGE } from '../../../../constant/routes';
import GetStartedForm from "../../../Home/components/GetStartedForm";


const Card = styled.div`
position: relative;
font-family:  "Poppins", sans-serif;
width: 100%;
height: 100%;
max-height: 100%;
padding-block: 20px;
padding-inline: 20px;
background: #FFFFFF;
box-shadow: 0px 8px 24px rgb(0 0 0 / 10%);;
border-radius: 17px;
display: flex;

.modal-content {
    background-color: #2439B2 !important;
}

span {
    position: absolute;
    top: 0;
    right:0;
    color: #fff;
    font-size: .7rem;
    padding: 6px;
    background: #2439B2;
border-radius: 0px 15px 0px 0px;
}

${props =>
        props.isActive &&
        css`
        border: 2px solid #0013808F;

        .contact-btn {
    button {
        background-color: #2439B2 !important;
        color: #fff;
        &:hover {
            background-color: #061468 !important;
           
        }
    }
}
    `};

.content {
    margin: auto;

    p {
        &:first-child{
            font-family: "Poppins", sans-serif;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 0;
        }
    }

    p#usage {
        font-size: 16px;
          font-weight: 400;
          color: #171717;
    }

     p#slogan {
        font-size: 16px;
          font-weight: 600;
         color: #131313;
         margin-top: 30px;
         margin-bottom: 0;
    }

     p#text {
         margin-top: 5px;
         padding-inline: 30px;
        font-size: 14px;
          font-weight: 400;
         color: #131313;
    }

    h5 {
       
         font-size: 31px;
          font-weight: 500;
          margin: 20px 0;
          
    }
}

.contact-btn {
      margin-top: 60px;
    button {
        padding-inline: 40px;
        padding-block: 7px;
        background-color: #fff;
        border: 1px solid #D4D4D4;
        border-radius: 26px;
        font-size: 14px;
         font-weight: 600;
        transition: all .4s ease;

        &:hover {
            background-color: #2439B2;
            color: #fff;
        }
    }
}

.linkBtn {
    margin: 10px 0;
    font-size: 12px;
    
    a{
        cursor: pointer;
        color: #2439B2 !important;
    }
}



.content-two {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-block: 20px;
        p {
          font-size: .8rem;
          font-weight: 600;
          margin-bottom: 0;

        
    }

    h5 {
        font-family: "Poppins", sans-serif;
        font-size: 2rem;
          font-weight: 800;
          margin: 20px 0; 
    }

    .contact-btn {
        margin-top: 0px;
      margin-bottom: 30px;
    button {
       
       color:#2439B2 ;

        &:hover {
            background-color: #2439B2;
            color: #fff;
        }
    }
}




/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
/* Styles */
padding-block : 50px;
p {
          &:first-child{
            font-size: 22px;
          }


          &:last-child{
            font-size: 16px;
          }

        
    }

    h5 {
        font-size: 40px;
          margin: 20px 0; 
    }
}

}



`




export const PriceCard = ({ data, hasBuy, isActive }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalContent, setModalContent] = React.useState('')
 
console.log(data)
    const handleShow = (value) => {
        setModalShow(true)
        setModalContent(<GetStartedForm  value={value} hasText/>)
    }
    
    return (
        <>
            <Card isActive={isActive}>
                {isActive && (<span>Popular</span>)}
                <div className='content'>
                    <p>{data.basic}</p>
                    <h5>{'$' + data.amount}</h5>
                    <p id='usage'>{data.usage}</p>
                    <p id='slogan'>{data.slogan}</p>
                    <p id='text'>{data.text}</p>
                    <div className='contact-btn'>
                        {/* <CustomButton  text="Get Started" /> */}
                        <button onClick={() => handleShow(data)}>Get Started</button>
                    </div>
                    <div className='linkBtn'>
                            {/* <CustomLinkBtn text="Features" url="" /> */}
                           {
                               hasBuy && ( <a>Buy Now</a>)
                           }
                        </div>
                </div>
            </Card>

            <CustomModal  show={modalShow} onHide={() => setModalShow(false)} className="getStarted">
             {modalContent}
           </CustomModal>
        </>
    );
};



export const CustomPricingCard = () => {
    // const [modalShow, setModalShow] = React.useState(false);
    return (
        <Card className='custom-pricing-card'>
            <div className='content-two'>
                <div>
                    <p>Let's Talk</p>
                    <h5>Custom <br /> Pricing</h5>
                    <p>Unlimited number of users and <br /> specialized features</p>
                </div>
                <div>
                    <div className='contact-btn'>
                    <Link to={CONTACT_PAGE}>
                        <CustomButton text="Talk to us"  />

                    </Link>
                    </div>
                    <div className='linkBtn'>
                        {/* <CustomLinkBtn  text="Features" url={REQUEST_PAGE} /> */}
                        <a>Learn More</a>
                    </div>
                </div>
            </div>
            {/* <CustomModal  show={modalShow} onHide={() => setModalShow(false)} className="prices" >
             <div className="d-flex">
                <ul className="m-auto">
                <h5>Buy Now</h5>
                <li>{
                    growthFeatures.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))
                }</li>
                </ul>
             </div>
           </CustomModal> */}
        </Card>
    )
}

