import React from 'react'
import { CheckedIcon, CrossIcon, PointerIcons } from '../../../../Components/SVGs'

const FeacturesSection = ({data}) => {
    
  return (
    <div className="feature_table">
        <table style={{width: '100%'}}>
<tbody>
<tr style={{height: "80px"}}>
<td style={{height: "80px", border: "none"}} colSpan={2}>&nbsp;&nbsp;&nbsp;</td>
<td style={{height: "80px", width: 200}} colSpan={2}>
  <div>
  <p>Basic</p>
    <h5> <sup>$</sup><span>{data.free.amount}</span><small>/month</small></h5>
  </div>
</td>
<td style={{height: "80px", width: 200}} colSpan={2}>
<div>
<p>Premium</p>
<h5> <sup>$</sup><span>{data.basic.amount}</span><small>/month</small></h5>
</div>
</td>
<td style={{height: "80px", width: 200}} colSpan={2}>
<div>
<p>Business</p>
<h5> <sup>$</sup><span>{data.growth.amount}</span><small>/month</small></h5>
</div>
</td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}>Features</td>
<td style={{height: "60px"}} colSpan={2}>&nbsp;&nbsp;</td>
<td style={{height: "60px"}} colSpan={2}>&nbsp;&nbsp;</td>
<td style={{height: "60px"}} colSpan={2}>&nbsp;&nbsp;</td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Number of Trips</td>
<td style={{height: "60px"}} colSpan={2}>300</td>
<td style={{height: "60px"}} colSpan={2}>Unlimited</td>
<td style={{height: "60px"}} colSpan={2}>Unlimited</td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Number of Users</td>
<td style={{height: "60px"}} colSpan={2}>3</td>
<td style={{height: "60px"}} colSpan={2}>Unlimited</td>
<td style={{height: "60px"}} colSpan={2}>Unlimited</td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Business Intelligence Dashboard</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> BVO & Assistant Management</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> BRV Management</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Settings</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Compliance Tracker</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Customer Service</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Journey Communication Log</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Customer Center</td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Support</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Losses</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Violations</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Report</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Account Management</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Fuel Management</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Workshop</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
<tr style={{height: "60px"}}>
<td style={{height: "60px"}} colSpan={2}><PointerIcons /> Depot Loading</td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CrossIcon /></td>
<td style={{height: "60px"}} colSpan={2}><CheckedIcon /></td>
</tr>
</tbody>
</table>

    </div>
  )
}

export default FeacturesSection