import * as React from 'react';
import $ from 'jquery';
import pricingContent from '../../../../../content/pricingPageContent.json';
import { CustomPricingCard, PriceCard } from './PriceCard';
import FeacturesSection from './FeacturesSection';

const PricingComponent = () => {
    const [prices, setPrices] = React.useState(pricingContent.monthlyContent);

    // Injected Jquery code
    React.useEffect(() => {
        $(function () {
            var taeb = $(".taeb-switch");
            taeb.find(".taeb").on("click", function () {
                var $this = $(this);

                if ($this.hasClass("active")) return;

                var direction = $this.attr("taeb-direction");

                taeb.removeClass("left right").addClass(direction);
                taeb.find(".taeb.active").removeClass("active");
                $this.addClass("active");
            });
        });
    }, [])
    // 

    return (
        <>
            <div className="wrapper">
                <div className="taeb-switch left text-center">
                    <div className="taeb active" taeb-direction="left" onClick={() => setPrices(pricingContent.monthlyContent)} aria-hidden="true">Monthly</div>
                    <div className="taeb" taeb-direction="right" onClick={() => setPrices(pricingContent.yearlyContent)} aria-hidden="true">Yearly  <span>10% OFF</span></div>

                </div>

            </div>
            <div className='row'>
                <div className='col-sm-12 col-md-6 col-lg-4 mb-5 mb-md-0'>
                    <PriceCard data={prices.free} />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4 mb-5 mb-md-0'>
                    <PriceCard data={prices.basic} hasBuy isActive />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4 mx-auto'>
                    <PriceCard data={prices.growth} hasBuy />
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                <FeacturesSection data={prices}/>
                </div>

            </div>
            
            <div className='row my-5'>
                <div className='col-sm-12 col-md-12 col-lg-9 mx-auto custom-pricing'>
                    <CustomPricingCard data={pricingContent.CustomPricing} />
                </div>

            </div>
        </>
    );
};

export default PricingComponent;
