import * as React from "react"
import HeaderComponent from "../../../Components/HeaderComponent";
import Layout from '../../../Components/Layout';
import Seo from "../../../Components/Seo";
import TripPricing from '../../../modules/TripSuite/Pricing'

const index = () => {
    return (
        <>
            <Seo title="Pricing | Trip Suite, Webbermill"/>
            <Layout className="Pricing">
                <title>Pricing Page</title>
                <HeaderComponent hasPrice loginUrl="http://transport.webbermill.com/"/>
                <TripPricing />
            </Layout>
        </>
    );
};

export default index;